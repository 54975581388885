.phone_texthead {
  /* font-family: "Poppins", sans-serif; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.phoneNumber {
  color: #616161;
  /* font-family: "Poppins", sans-serif; */
  font-size: 20px;
  font-weight: 600;
} 
.main_text{
  font-size: 28px;
  font-weight: 600;
  width: 100%;
  text-align: start;
}
.custom_texthead{
  text-align: center;
  margin-bottom: 10px;
}
.custom_paratext{
  color: #616161;
  font-weight: 500;
  font-size: clamp(14px, 3px + .8vw, 16px);
}
.f_18{  font-size: clamp(14px, 3px + .8vw, 18px);}
.distance_box{
  padding: 20px 15px;
  border: 1px solid #E8E6EA;
  border-radius: 9px;
  margin-top: 10px;
}
.distance_slider .MuiSlider-thumb {
  border: 3px solid #fff;
}


@media screen and (max-width: 1533px){
  .main_text{
  font-size: 22px;
  font-weight: 600;
}
.phoneNumber {
  font-size: 16px;
} 
}