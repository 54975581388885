.continuePhone_otpBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.continuePhone_otpBox input {
  width: 40px !important;
  height: 40px;
  font-weight: 600;
  font-size: clamp(20px, 2vw, 24px);
  border: 1px solid #EC1B77;
  border-radius: 5px;
  outline: none;
  /* background: #EC1B77;
  color: #fff; */
}

.phone_texthead {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.renderSeparator {
  visibility: hidden;
  padding: 5px;
}

.verification_code_Text {
  color: #616161;
  font-weight: 500;
  font-family: "Josefin Sans", sans-serif;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  line-height: 16px;
}

.bottom_resend_div {
  text-align: center;
  font-weight: 500;
  margin-top: 14px;
}

.resend_mob_number {
  color: #ec1b77;
  cursor: pointer;
}

.next_button {
  display: flex;
  color: #fff;
  background: linear-gradient(180deg, #ec1b77 0%, #9e1f63 100%);
  justify-content: center;
  padding: 10px;
  font-size: clamp(16px, 5px + 1vw, 20px);
    line-height: 1.5;
    border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
  width: 100%;
  margin: 0px;
}
