/* html {
  height: -webkit-fill-available; 
} */
/* We have to fix html height */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  overflow-x: hidden;
  width: 100%;
  color: #000;
  font-family: "Josefin Sans", sans-serif;
   font-family: "Poppins", sans-serif;
}
p {
  line-height: 140%;
  color: #484848;
  font-weight: 400;
}
.signup-page-typo {font-size: 1rem;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* styles.css */
.layout-container {
  display: flex;
  flex-direction: column;
}

.layout-content {
  flex-grow: 1;
}
.navbar{
  width: 100%;
  display: flex; 
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
}
.logo {
  margin-right: 1rem;
}
.link {
  color: #fff !important;
  text-decoration: none;
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff !important;
  background-color: #F7F0F3 !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: inherit;
}

.next_button, .deny_button, .back_button, .btn{
  padding: 0.375rem 0.75rem;
  font-size: clamp(16px, 5px + .8vw, 20px);
  line-height: 1.5;
  border-radius: 5px;
}
.btn-primary {
  color: #fff;
  background: linear-gradient(180deg, #ec1b77 0%, #9e1f63 100%);;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.banner_login {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.66) 0%,
    rgba(247, 214, 232, 0.66) 100%
  );
}

.login_form {
  background: #fff;
}

.banner-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.banner-section img {
  width: 475px;
  height: 475px;
}

.login-form .login-section {
  display: flex;
  justify-content: center;
}

.social-media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
  max-height: 45%;
  justify-content: center;
}
.container_section_login, .container_section { width: min(90vw, clamp(290px, 40vw ,450px)); margin-inline: auto; }

.socialmedia-box {
  border: 1px solid #ec1b77;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px;
  width: 100%;
  /* width: min(55%, 500px,); */
  /* width: min(90vw, clamp(290px, 29vw ,380px));  */
  cursor: pointer;
}
.socialmedia-box img {
  margin-top: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}

.continuePhone {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.continuePhone .social-media-container .socialmedia-box .signup-page-typo {
  font-family: inherit;
    font-size: 18px;
  font-weight: 500;
}

.continuePhone .form-control {
  height: 45px !important;
  width: 98% !important;
  font-weight: 500;

  font-family: "Josefin Sans", sans-serif;
}
.react-tel-input .form-control , .input-box input{
  width: 100% !important; max-width: 100%; 
  height: clamp(45px, 5px + 3.5vw, 60px) !important;  
   font-size: clamp(16px, 2vw, 20px) !important;  font-family: "Josefin Sans", sans-serif !important; }
.input-box input ,.input-box select{padding-block: 0 !important; box-shadow: none !important; border-radius: 5px;  font-family: "Josefin Sans", sans-serif !important; }
.continuePhone .react-tel-input {
  margin-top: 10px !important;
}

.next_button,.deny_button,.back_button  {
  display: flex;
  color: #fff;
  background: linear-gradient(180deg, #ec1b77 0%, #9e1f63 100%);
  justify-content: center;
  padding: 10px;
  margin-top: 25px;
  cursor: pointer;
  width: 100%;
  border: none;
  
}

.deny_button {
  background: #424242;
  /* width: 60%; */
}

.back_button {
  background: #000000;
  /* width: 60%; */
}

.common_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.container_section {
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container_section_login {
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uploaded-img {
  grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
  gap: 10px;
  display: grid;
  width: 100%;
}

.image-upload label span{    font-size: 50px;
  color: #ec1b77;}
.image-upload label {
  background: #fceef6;
  width: 100%;
  display: flex;
  height: 150px;
  border: 1px dashed #ec1b77;
  border-radius: 7px;
  align-items: center;
    justify-content: center;
}

.image-upload {position: relative;}
.image-upload > input {
  visibility: hidden;
  position: absolute;
  inset: 0;
  height: 100% !important;
}

.success_text {
  color: #000;
  text-align: center;
  /* font-family: Poppins; */
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
}

.custom-select-dropdown{
  border: 1px solid #cacaca;
  width: 100%;
  height: clamp(45px, 5px + 3.5vw, 60px) ;
  font-size: 16px;
  padding-inline: 12px;
  color: #827b7b;
  margin-top: 16px;
  border-radius: 5px;
  font-family: "Josefin Sans", sans-serif;
}

/* ABOUT US CSS ----------- */

.main_sec_content {
  color: #616161;
  font-family: Josefin Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.main_sec_text {
  color: #000;
  text-align: center;
  /* font-family: Poppins; */
  font-size: clamp(20px, 2vw, 28px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.arrow_icon{
  
}

/* about us page css start here  */
.main_sec_content {
  padding: clamp(18px, 2vw, 25px);
  border: 1px solid #E8E6EA;
  border-radius: 5px;
  /* max-height: 55vh; */
  overflow: auto;
}
.main_sec_content p {
  margin-block: 0 15px;
  font-size: clamp(16px, 1.5vw, 18px);
}
.main_sec_content p:last-child{margin-bottom: 0;}
.main_top_heading .arrow_icon img {
  /* padding: 5px; */
  border: 1px solid #E8E6EA;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}
.arrow_icon {
  display: flex;
  align-items: center;
}
.main_top_heading {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.main_top_heading .main_sec_text {
  margin-left: 15px;
}
.custom-margin{
  margin: 45px;
}
/* about us page css end here  */

/* home screen css start here  */
.box_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.main_img_box {
  height: 385px;
}
.main_box {
  justify-content: space-evenly;
  margin: 25px 0 !important;
  align-items: center;
  width: 100% !important;
  row-gap: 15px;
}

.bottom_heading{
color: #1D1A33;
text-align: center;
/* font-family: Poppins; */
font-size: clamp(30px, 3vw, 40px);
font-weight: 600;
margin-bottom: 15px;
}
.bottom_txt_box {
  text-align: center;
  margin-bottom: 45px !important;
}
.bottom_txt_box p{
 margin: 15px 0;
}
.footer {
  background: #F7F0F3;
  padding: 10px;
  width: 100% !important;
}
.footer_box {
  display: flex;
  align-items: center;
}
.footer_box a {
  text-align: center;
  margin-top: 4px;
  width: min(clamp(160px, 10vw, 190px), 70%);

}
.footer_box .login-section {
  width: 35%;
  margin: 0 15px;
}
.footer_box .login-section img {
  height: auto;
  max-width: 100%;
}
.main-slider {
  overflow: hidden;
}
.footer_img {
  max-width: 100%;
  height: auto;
  /* width: min(190px, 70%); */
  border-radius: 6px;
  margin-top: 5px;
}
.footer-ul {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.footer-ul li {
  list-style-type: none;
  margin: 0 clamp(8px,  2px + 1vw, 20px);
}
.footer_link a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.slider_img{
/* max-height: 831px; */
width: 100%;
height: 100%;
object-fit: cover;
}
.slider-item {
  height: calc(100vh - 84px);
}
.slick-dots li button:before {
  opacity: 1 !important;
  color: white !important;
  font-size: 12px !important;
}
.slick-dots li.slick-active button:before {
  color: #9E1F63 !important;
}
.slick-dots li button {
  color: #fff !important;
}
.slick-dots {
  position: absolute !important;
  bottom: 20px !important;
}
/* home screen css end  here  */

/* looking for page css start here */
.icon_box {
  padding: 0 !important;
  width: 33% !important;
  text-align: center;
  height: 100px;
  max-width: 100%;
  margin-bottom: 10px !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  /* justify-content: space-between; */
}
.icon_box  p{font-size: clamp(14px, 4px + .8vw, 16px);}
.icon_img {
  height: 50px;
  width: 50px;
  background: white;
  padding: 8px;
  border-radius: 48px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.looking_for_btn{
max-width: 50%;
margin: auto;
}
.date_input {
  font-size: 20px;
  padding: 9px;
  /* color: #a2a2a2; */
  color: #000;
}
.select_gender {
  font-size: 18px !important;
  color: #a2a2a2 !important;
  font-weight: 500 !important;
}
.select_gender svg{
  fill: #EC1B77 !important;
    height: 40px !important;
    width: 40px !important;
    font-size: 41px;
}
.lookingforpage { width: 100%;
  max-width: 600px;}
/* looking for page css end here */

/* responsive css start here  */
@media screen and (max-width: 1750px){
  .custom-margin{
    margin: 30px 45px;
  } 
  
}
@media screen and (max-width: 1533px){
  .custom-margin {
    margin: 20px clamp(15px, 3vw, 45px);
  }
  .main_top_heading {
    margin-bottom: 15px;
  }
  .slider_img{
    max-height: 100vh;
  }
  
}
@media screen and (max-width: 899px){
  .mid_box{
    flex-direction: column-reverse !important;
  }
  .footer{padding: 20px 10px;}
}

@media screen and (max-width: 574px){
  .slider-item {
    height: calc(70vh - 84px);
}

.container_section_login ,.container_section  {
  width: calc(100% - 40px);
}
.icon_box {
  width: 49% !important;
}
}
.container_section_login, .container_section{
  padding-block: 30px;}
  
.uploaded-img{  overflow-y: auto; max-height: 230px;}
.upload_section .uploaded-img::-webkit-scrollbar{width: 5px; background: #f1f1f1 ;}
.upload_section .uploaded-img::-webkit-scrollbar-thumb {
  background: #888; 
}
select{
  padding: 10px;
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("assets/select-arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}
/*  .upload_section .uploaded-img::-webkit-scrollbar-track {background: #752929; } */
@media screen and (min-width: 1366px){
  .upload_section{ height: calc(100vh - -6px); justify-content: unset; overflow-y: auto;}
  .upload_section::-webkit-scrollbar{width: 0;}
}
@media screen and (min-width: 600px){
  .login-container,.container_section_login, .container_section{ height: calc(100vh - 40px);padding-block: 0;}


}
/* responsive css end here  */


